<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="customers"
        ref="refUserListTable"
        class="position-relative"
        :items="customers"
        :fields="tableColumns"
        primary-key="id"
        responsive
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(full_name)="data">
          <b-media
            vertical-align="center"
            class="align-items-center"
            role="button"
            @click="myHandler(data.item)"
          >
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.full_name }}
            </span>
          </b-media>
        </template>
        <template #cell(birthday)="data">
          {{ data.item.birthday ? moment(data.item.birthday).format('DD-MM-YYYY') : '_' }}
        </template>
        <!-- <template #cell(gender)="data">
          {{ renderGender(data.item.gender) }}
        </template> -->
        <!-- Column: Actions -->
        <template #cell(actions)>
          <b-form-checkbox />
        </template>
      </b-table>
      <div v-else class="text-center">
        <b-spinner style="width: 5rem; height: 5rem" class="m-3" variant="info" />
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPages"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import * as moment from 'moment';
import store from '@/store';
import router from '@/router';
import { computed, ref, onUnmounted, watch } from '@vue/composition-api';
import constRouter from '@/constants/constRouter';
import UsersListFilters from './CustomerListFilters.vue';
import userStoreModule from '../customerStoreModule';
import UserListAddNew from './CustomerListAddNew.vue';

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BSpinner,
    vSelect,
  },
  setup(props, { root }) {
    const USER_APP_STORE_MODULE_NAME = 'app-customer';
    const customers = ref(null);
    const refUserListTable = ref(null);

    const perPage = ref(30);
    const totalPages = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [30, 50, 100, 300];

    const dataMeta = computed(() => {
      const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalPages.value,
      };
    });

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });

    const renderGender = (gender) => {
      if (gender === 0) {
        return 'Nam';
      }
      return 'Nữ';
    };
    const myHandler = (val) => {
      root.$router.push(`customer/${val.id}`);
    };

    const getCustomers = () => {
      customers.value = null;
      const queries = router.currentRoute.query;
      store
        .dispatch('app-customer/fetchCustomers', {
          perPage: perPage.value,
          page: currentPage.value,
          ...queries,
        })
        .then((response) => {
          const { data, meta } = response.data;
          customers.value = data;
          totalPages.value = meta.total;
        });
    };

    watch([currentPage, perPage], () => {
      getCustomers();
    });

    getCustomers();

    return {
      // Sidebar
      perPage,
      currentPage,
      totalPages,
      dataMeta,
      perPageOptions,
      refUserListTable,
      customers,
      constRouter,
      renderGender,
      moment,
      myHandler,
    };
  },
  data() {
    return {
      tableColumns: [
        { key: 'actions', label: '', thStyle: 'width:20px' },
        {
          key: 'id',
          label: this.$t('customers.id'),
          thStyle: 'width: 110px',
          thClass: 'text-center',
        },
        { key: 'full_name', label: this.$t('customers.full_name') },
        {
          key: 'code',
          label: this.$t('customers.code'),
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'nic_number', label: this.$t('customers.nic_number') },
        {
          key: 'contracts_count',
          label: this.$t('customers.contracts_count'),
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'created_at', label: this.$t('contracts.created_at') },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 110px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
