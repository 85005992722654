<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row class="align-items-end">
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Họ tên</label>
          <b-form-input
            class="d-inline-block mr-1"
            :value="fullName"
            @input="(val) => debounceSearch('fullName', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Số điện thoại</label>
          <b-form-input
            class="d-inline-block mr-1"
            :value="phoneNumber"
            @input="(val) => debounceSearch('phoneNumber', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>CMND/CCCD </label>
          <b-form-input
            class="d-inline-block mr-1"
            :value="nicNumber"
            @input="(val) => debounceSearch('nicNumber', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-button variant="outline-primary" @click="handleCancel()">Hủy tìm kiếm</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    fullName: {
      type: String,
      default: null,
    },
    phoneNumber: {
      type: String,
      default: null,
    },
    nicNumber: {
      type: String,
      default: null,
    },
  },
  methods: {
    debounceSearch(id, val) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit(`update:${id}`, val);
      }, 1000);
    },
    handleCancel() {
      this.$emit('update:fullName', null);
      this.$emit('update:phoneNumber', null);
      this.$emit('update:nicNumber', null);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
