import jwt from '@/auth/jwt/useJwt';

export function customerList(condition) {
  return jwt.axiosIns.get('/customers', {
    params: {
      ...condition,
    },
  });
}

export function customerDetail(id) {
  return jwt.axiosIns.get(`/customers/${id}`);
}
